<template>
  <div class="template-wrapper">
    <div class="card">
      <div v-if="template.header" class="card-header">
        <template v-if="template.header.type === 'TEXT'">{{ template.header.body }}</template>
        <template v-else-if="template.header.type === 'IMAGE'">
          <img class="attachment" :src="template.header.example_parameter">
        </template>
        <template v-else-if="template.header.type === 'VIDEO'">
          <template
            v-if="template.header.example_parameter"
          >
            <video class="attachment" :src="template.header.example_parameter"/>
          </template>
          <div v-else class="image-container">
            <img src="/assets/img/template-video-alt.svg">
          </div>
        </template>
        <template v-else>
          <template
            v-if="template.header.example_parameter"
          >
            <object class="attachment" :data="template.header.example_parameter"></object>
          </template>
          <div v-else class="image-container">
            <img src="/assets/img/document_preview.png">
          </div>
        </template>
      </div>
      <div class="card-body">
        <p>{{ template.body.text }}</p>
        <template v-if="template.type === 'AUTH'">
          <p>Seu código de verificação é <strong>{{template.button_parameter}}</strong></p>
        </template>
        <small class="text-muted" v-if="template.footer">{{ template.footer.body }}</small>
      </div>
      <div v-if="template.buttons && template.buttons.type === 'CALL_TO_ACTION'"
           class="card-footer">
        <div class="call-to-action" v-if="template.buttons.call_to_action_website">
          <a :href="template.buttons.call_to_action_website.url" target="_blank" rel="noopener">
            <span class="material-symbols-outlined">
              open_in_new
            </span>
            {{ template.buttons.call_to_action_website.text }}
          </a>
        </div>
        <div v-if="template.buttons.call_to_action_dial_phone">
        </div>
      </div>
    </div>
    <template v-if="template.buttons">
      <div class="quick_replies" v-if="template.buttons.quick_replies">
        <ButtonComponent v-for="(reply, n) in template.buttons.quick_replies" :key="n" is-block>
          {{ reply }}
        </ButtonComponent>
      </div>
    </template>
  </div>
</template>

<script>
import ButtonComponent from '@/components/ui/ButtonComponent.vue';
import headerImage from '@/assets/img/card_header.png';

export default {
  name: 'TemplatePreview',
  components: { ButtonComponent },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headerImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.template-wrapper {
  padding: 22px 92px 22px 9px;
  background: #F7F7F7;

  .card {
    margin-bottom: 0;
    box-shadow: 0 1px .5px rgba(11, 20, 26, .13);

    .card-header {
      padding: 5px;

      .image-container {
        text-align: center;
        margin-bottom: 10px;
        background: #eff2f7;
        padding: 25px;
        width: 100%;
      }

      .attachment {
        border-radius: 9px;
        width: 100%;
      }
    }

    .card-body {
      padding: 7px;
    }

    .card-footer {
      border: 1px solid rgba(17, 27, 33, 0.06);

      .call-to-action {
        text-align: center;

        a {
          font-size: 14px;

          span {
            vertical-align: text-top;
          }
        }
      }
    }
  }

  .quick_replies {
    display: block;

    button {
      width: auto;
      flex-direction: row;
      background-color: #fff;
      color: #009de2;
      box-shadow: 0 1px .5px rgba(11, 20, 26, .13);
    }
  }

  button {
    margin: 1.5px 3px;
  }
}
</style>
